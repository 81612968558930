@use "sass:math";

$_form-box-shadow: inset 0 1px 3px rgba(#000, 0.06);
$_form-box-shadow-focus: $_form-box-shadow, 0 0 5px rgba($action-color, 0.7);

fieldset {
  background-color: transparent;
  border: 0;
  margin: 0;
  padding: 0;
}

legend {
  font-weight: 600;
  margin-bottom: 4px;
  padding: 0;
}

label {
  display: block;
  font-weight: 600;
  margin-bottom: 4px;
}

input,
select,
textarea {
  display: block;
  font-family: var(--base-font-family);
  font-size: 16px;
  color: palette(blue, 10);
}

textarea,
[type=text],
[type=email],
[type=password],
[type=search] {
  appearance: none;
  background-color: $input-background-color;
  border: $base-border;
  border-radius: $base-border-radius;
  box-shadow: $_form-box-shadow;
  box-sizing: border-box;
  margin-bottom: $small-spacing;
  padding: math.div($base-spacing, 3);
  width: 100%;

  &:hover {
    border-color: shade($base-border-color, 20%);
  }

  &:focus {
    border-color: $action-color;
    box-shadow: $_form-box-shadow-focus;
    outline: none;
  }

  &:disabled {
    background-color: shade($base-background-color, 5%);
    cursor: not-allowed;

    &:hover {
      border: $base-border;
    }
  }

  &::placeholder {
    color: palette(gray, dark);
  }
}

textarea {
  resize: vertical;
}

[type="checkbox"],
[type="radio"] {
  display: inline;
  margin-right: math.div($small-spacing, 2);
}

[type="file"] {
  margin-bottom: $small-spacing;
  width: 100%;
}

select {
  margin-bottom: $small-spacing;
  width: 100%;
}

[type="checkbox"],
[type="radio"],
[type="file"],
select {
  &:focus {
    outline: $focus-outline;
    outline-offset: $focus-outline-offset;
  }
}

.request-notice {
  color: palette(gray, light);
  margin-top: 10px;
  font-size: rem(fontsize(base));
  line-height: rem(20px);

  p {
    margin-bottom: 0;
  }

  &.error {
    color: palette(status, error);
  }

  &.success {
    color: palette(status, success);
  }
}
