/****************
* text-with-media
*/
.text-with-media {
  @include media(l) {
    padding-top: 170px;
  }

  .inner-container {
    height: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
  }


  /****** Text */
  &__text {
    @include media(xs) {
      order: 1;
      width: 100%;
      padding: 85px gutter(s) 10% gutter(s);
      font-size: rem(fontsize(s));
    }
    @include media(m) {
      padding-bottom: 60px;
    }
    @include media(l) {
      order: 0;
      width: 40%;
      padding: 0px 50px 8% 50px;
      font-size: rem(fontsize(m));
    }
    @include media(xl) {
      width: 50%;
      padding: 0px 100px 8% 100px;
    }

    .text__container {
      display: flex;
      @include media(xs) {
        flex-direction: column;
      }
      @include media(m) {
        flex-direction: row;
        align-items: center;
        justify-content: space-between;     
      }
      @include media(l) {
        flex-direction: column;
        margin: 0px auto;
        max-width: 520px;
        justify-content: unset;
        align-items: unset;
      }

      li {
        padding-left: 1rem;
        text-indent: -1rem;
        
        &:before {
          content: '\2022';
          color: palette(blue, 02);
          padding-right: 0.6rem;
        }
      }

      .text__content {
        max-width: 100%;
      }

      .text__chunk {
        display: inline;
      }
      
      .text__item {
        cursor: pointer;
        border-bottom: 2px solid;
        border-color: palette(gray, base);
        &.active {
          border-color: palette(yellow, base);
        }
      }
      
      .text__separator {
        color: palette(blue, 02);
        padding: 0px 4px;
      }
      
      .button {
        margin-top: 22px;
      }
    }
  }


  /****** Media */
  &__media {
    align-self: flex-start;
    order: 2;
    width: 100%;
    padding: 0px;
    @include media(m) {
      
    }
    @include media(l) {
      order: 0;
      width: 60%;
    }
    @include media(xl) {
      width: 50%;
    }
    
    .media__container {
      height: 100%;
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    &:last-child .media__item {
      padding: 0px 20px;
      text-align: center;
      @include media(l) {
        padding: 0px;
        text-align: right;
      }
      .inner-container--maxwidth & {
        text-align: center;
      }
    }
    &:first-child .media__item {
      padding: 0px 20px;
      text-align: center;
      @include media(l) {
        padding: 0px;
        text-align: left;
      }
      .inner-container--maxwidth & {
        text-align: center;
      }
    }

    .media__item {
      opacity: 0;
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0px;
      left: 0px;
      z-index: 10;
      transition: opacity 0.6s ease-in;

      &.active {
        opacity: 1;
      }

      img,
      video {
        box-shadow: 0 20px 24px rgba(0, 0, 0, 0.6);
        height: auto;
        max-height: 100%;
        max-width: 100%;
      }
      
      video {
        transition: opacity 0.3s ease-in;
        &.loading {
          opacity: 0;
        }
        &.ready {
          opacity: 1;
        }
      }
    }
    
    .media__placeholder {
      img {
        max-height: 85vh;
      }
    }
    
    .media__navigation {
      position: absolute;
      z-index: 101;
      bottom: -30px;
      left: 0px;
      width: 100%;
      text-align: center;

      .navigation__item {
        display: inline-block;
        cursor: pointer;
        width: 12px;
        height: 12px;
        border-radius: 50%;
        background-color: palette(blue, 02);
        margin-right: 10px;
        &:last-child { margin-right: 0px; }
        &.active {
          background-color: palette(yellow, base);
        }
      }
    }
  }


  /****** Testimonials */
  .testimonial {
    @include media(xs) {
      order: -1;
      max-width: 450px;
      margin-bottom: 50px;
      margin-top: 0;
    }
    @include media(m) {
      order: unset;
      flex: 1 0 330px;
      margin-top: 0;
      margin-bottom: 0;
      margin-left: 50px;
    }
    @include media(l) {
      flex: none;
      max-width: 450px;
      margin-top: rem(72px);
      margin-left: 0;
    }
  }
}
