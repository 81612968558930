:root {
  --background-primary: #002D4F;
  --background-secondary: #09233C;
  --background-secondary-alt: #083256;
  --background-tertiary: #184772;
  --background-tertiary-alt: #164067;

  --foreground-highlight: #00CAF4;
  --foreground-highlight-alt: #FFC600;
  --foreground-highlight-alt-hover: #FFD94E;
  --foreground-highlight-alt-active: #F1B900;

  --base-font-family: "proxima-nova", "Lucida Grande", "Verdana", "Helvetica Neue", "Helvetica", sans-serif;

  --nav-background: linear-gradient(var(--background-secondary), #193652);
  --nav-link-color: #D8F7FF;
  --nav-download-background: linear-gradient(#29D1FF, #00ACDB);
  --nav-download-hover-background: linear-gradient(#34D3FF, #01BAED);
  --nav-secondary-background: linear-gradient(rgb(96 129 160 / 75%), rgb(45 72 99 / 75%));
  --nav-secondary-link-color: #fff;
  --nav-secondary-link-hover-background: rgb(103 133 163 / 0.5);
  --nav-secondary-seperator-color: rgb(20 28 37 / 0.4);

  --hero-title-color: var(--foreground-highlight);
  --hero-tagline-color: #fff;
  --hero-download-button-background: var(--foreground-highlight-alt);
  --hero-download-button-background-hover: var(--foreground-highlight-alt-hover);
  --hero-download-button-background-active: var(--foreground-highlight-alt-active);
  --hero-switch-platform-color: #fff;
  --hero-switch-platform-background-hover: rgba(255, 255, 255, 0.07);
  --hero-switch-platform-text-decoration: underline;
}

.color-scheme-royal {
  --background-primary: #081B2E;
  --background-secondary: #09233C;
  --background-secondary-alt: #09233C;
  --background-tertiary: #164067;
  --background-tertiary-alt: #164067;

  --foreground-highlight: #53DAFF;
  --foreground-highlight-alt: #F2BC00;
  --foreground-highlight-alt-hover: #FFCE22;
  --foreground-highlight-alt-active: #FFC600;

  --hero-title-color: linear-gradient(#EFF5FA, #AAC8E5);
  --hero-tagline-color: rgba(255, 255, 255, 0.72);
  --hero-download-button-background: linear-gradient(var(--foreground-highlight-alt-hover), var(--foreground-highlight-alt));
  --hero-switch-platform-color: var(--foreground-highlight);
  --hero-switch-platform-text-decoration: none;
}

.color-scheme-dark {
  --background-primary: #1E1E1E;
  --background-secondary: #363739;
  --background-secondary-alt: #363739;
  --background-tertiary: #47494C;
  --background-tertiary-alt: #47494C;
}

.color-scheme-light {
  --background-primary: #fff;
  --background-secondary: #ECEEF0;
  --background-secondary-alt: #ECEEF0;
  --background-tertiary: #D3D7DB;
  --background-tertiary-alt: #D3D7DB;
}

.color-scheme-royal,
.color-scheme-dark,
.color-scheme-light {
  --base-font-family: Inter, sans-serif;
  /* fix for Chrome */
  font-feature-settings: 'liga' 1, 'calt' 1;
}

@supports (font-variation-settings: normal) {

  .color-scheme-royal,
  .color-scheme-dark,
  .color-scheme-light {
    --base-font-family: InterVariable, sans-serif;
  }
}
