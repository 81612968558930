/******* Button Bar */
.button-bar {
  text-align: center;
  color: palette(gray, white);

  .inner-container {
    padding-top: 80px;
  }

  .subline {
    font-size: rem(fontsize(l));
    line-height: rem(34px);
    max-width: 600px;
    margin: 0px auto;
  }

  &__buttons {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    max-width: 600px;
    margin: 0px auto;
    padding-bottom: 65px;
  }
}

.button__wrap--features {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0;
  height: 235px;
  background-image: url(asset-url('assets/img/illustrations/icon-heap.svg'));
  background-position: center;
  background-repeat: no-repeat;
  background-size: 501px;

  .button {
    background-color: palette(blue, 10);

    &:hover {
      background-color: palette(yellow, base);
    }
  }
}



/******* Testimonial Placeholders */
.testimonial-placeholder {
  display: none;
}




/******* Loading Overlay - Full-Page */
.loading-overlay--fullpage {
  position: fixed;
  z-index: 999;
  top: 0px;
  left: 0px;
  width: 100vw;
  height: 100vh;
  background-color: rgba(220, 220, 220, 0.85);
  background-image: url(asset-url('assets/img/icons/activity-indicator--big-blue.gif'));
  background-position: center center;
  background-repeat: no-repeat;
}




/******* Referrer & Non-Referrer Content */
.referrer-content {
  padding: 80px 0px;

  &__buttons {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    max-width: 600px;
    margin: 0px auto;
    margin-top: 30px;
  }
}

.referrer-content--none {
  text-align: center;

  .referrer-content__content {
    color: palette(gray, white);
    max-width: 700px;
    margin: 0px auto;
  }

  .referrer-content__release-notes-link {
    color: white;
    text-decoration: none;
    border-bottom: 2px solid palette(gray, base);
    transition: border-color $base-duration;

    &:hover,
    &:focus {
      border-color: palette(yellow, base);
    }
  }
}

.referrer-content--github,
.referrer-content--microsoft {
  .referrer-content__logo {
    float: left;
    width: 40%;
    margin-right: 70px;
  }

  .referrer-content__content {
    float: left;
    width: calc(60% - 70px);
    color: palette(gray, white);
    font-size: fontsize(l);
    @include clearfix;
  }

  .referrer-content__buttons {
    text-align: center;
  }
}




/******* Customers */
.customers {
  padding: 100px 0px 35px 0px;

  &__logos {
    margin: 65px auto 35px auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;

    svg.customer-logo {
      flex: 1 0 165px;
      margin: 0 auto 35px auto;
      max-width: 200px;
      max-height: 100%;
    }

    svg.customer-logo:last-child {
      @include media(xs) {
        display: none;
      }

      @include media(m) {
        display: block;
      }
    }
  }
}




/******* Feature Block "Learn / Docs / Support" */
.learn-docs-support {

  .subline {
    font-size: fontsize(l);

    @include media(xs) {
      margin-bottom: 40px;
    }

    @include media(l) {
      margin-bottom: 80px;
    }
  }

  .item-container {
    @include media(xs) {
      text-align: left;
      font-size: rem(fontsize(s));

      .item {
        display: flex;
        align-items: center;
        margin-bottom: 40px;
      }

      .item__image {
        flex: 0 0 100px;
        height: 100px;
        width: 100px;
        margin-right: 30px;
      }

      .item__text {
        flex: 1 1 200px;
      }
    }

    @include media(l) {
      display: flex;
      justify-content: space-between;
      text-align: center;
      font-size: rem(fontsize(m));

      .item {
        display: block;
        flex: 0 1 240px;
      }

      .item__image {
        flex: none;
        width: 135px;
        height: 135px;
        margin-right: auto;
        margin-left: auto;
        margin-bottom: 40px;
      }
    }
  }

  .item__image {
    border-radius: 50%;

    svg {
      max-width: 100%;
      max-height: 100%;
    }
  }
}




/******* Feature List "All Features" */
.all-features-list {
  .subline {
    font-size: rem(fontsize(l));
  }

  &__content {
    @include clearfix;
    color: palette(gray, white);

    @include media(xs) {
      p {
        font-size: rem(fontsize(base));
        line-height: rem(24px);
        margin-bottom: 0;
      }

      h4 {
        font-size: rem(22px);
        margin-top: 3*$base-spacing;
      }

      h5 {
        font-size: rem(fontsize(s));
        color: palette(gray, white);
      }
    }

    @include media(m) {
      p {
        font-size: rem(fontsize(m));
        line-height: $base-line-height;
      }

      h4 {
        font-size: rem(28px);
        margin-top: 3*$base-spacing;
      }

      h5 {
        font-size: rem(22px);
        color: palette(gray, white);
      }
    }
  }

  &__column {
    float: left;
    width: 45%;
    margin-right: 10%;

    &:last-child {
      margin-right: 0;
    }
  }

  ul {
    @include media(xs) {
      list-style-type: none;
      margin-left: 0;
    }

    @include media(m) {
      list-style-type: disc;
      margin-left: $base-spacing;
    }

    color: palette(gray, light);

    a {
      color: palette(gray, light);
    }

    li {
      margin-bottom: $base-spacing
    }
  }
}



/******* Release Notes */
#release-notes {
  $release-notes-left-column-width: 240px;
  $release-notes-left-column-margin: 30px;
  $release-notes-right-column-width: 790px;

  .os-selection {
    font-size: rem(29px);
  }

  .os-beta-selection {
     display: flex;
     justify-content: space-between;
     flex-wrap: wrap;
  }

  @media screen and (max-width: 1010px) {
  .os-switch {
    display: block;
    margin: 1rem 0;
    & span {
      margin-left: 0;
    }
  }

  .beta-channel-cta {
    margin-top: 1rem;
    flex-basis: 100%;
  }
}

  .release--major {
    margin-top: 120px;
    @include clearfix;

    &__header {
      @include media(xs) {
        flex-direction: column;
      }

      @include media(m) {
        flex-direction: row;
      }

      display: flex;
      align-items: baseline;
      border-bottom: 6px solid palette(blue, 09);
      margin-bottom: 80px;

      h1 {
        line-height: 50px;
        width: $release-notes-left-column-width;
        margin-right: $release-notes-left-column-margin;
        margin-bottom: 4px;
        color: palette(gray, white);
      }

      .navi--highlights,
      .navi--release-notes {
        display: inline-block;
        line-height: 50px;
        cursor: pointer;
        margin-right: 20px;
        font-weight: fontweight(bold);
        font-size: rem(fontsize(l));
        color: palette(gray, white);
        margin-bottom: -6px;

        &:hover {
          color: palette(yellow, base);
        }
      }

      .navi--active {
        border-bottom: 6px solid palette(blue, 02);
      }
    }

    &.release--legacy {
      .release--major__header {
        margin-bottom: 20px;
      }
    }



    .release__notes {
      .release {
        margin-bottom: 100px;
        @include clearfix;
      }

      .release__header {
        @include media(l) {
          float: left;
          width: $release-notes-left-column-width;
        }

        h2 {
          color: palette(gray, white);
          border-bottom: 2px solid palette(yellow, base);
        }

        .release__build-number {
          font-weight: bold;
        }

        .release__publish-date {
          display: block;
          font-size: rem(fontsize(s));
        }

        .release__download-link {
          display: block;
          font-size: rem(fontsize(s));

          a {
            text-decoration: none;
          }

          a:hover {
            text-decoration: underline;
          }
        }
      }

      .release__content {
        padding-top: 10px;

        @include media(l) {
          margin-left: ($release-notes-left-column-width + $release-notes-left-column-margin);
        }

        .section-header {
          display: inline-block;
          color: palette(blue, 02);
          font-size: rem(fontsize(l));
        }

        ul {
          font-size: fontsize(s);
          margin-bottom: 40px;

          li {
            list-style-type: disc;
            list-style-position: outside;
            margin-left: 1.2em;
          }
        }
      }
    }


    .release__highlights {
      .summary {
        font-size: rem(fontsize(l));
        margin-bottom: 45px;
        float: right;
        max-width: $release-notes-right-column-width;
        @include clearfix;
      }

      .highlight__item {
        margin-bottom: 80px;
        @include clearfix;
        float: right;
        max-width: $release-notes-right-column-width;
      }

      .highlight__title {
        color: palette(blue, 02);
        border-bottom: 2px solid palette(yellow, base);
        font-size: rem(fontsize(l));
        font-weight: fontweight(bold);
        margin-bottom: 20px;
      }

      .highlight__description {
        font-size: rem(fontsize(m));

        video,
        img {
          max-width: 100%;
        }
      }
    }
  }

  .release__extras {
    margin-top: 0.5rem;
    padding-top: 0.5rem;
    border-top: 1px solid #fff3;
  }

  .release__extras li {
    font-size: 14px;
  }

  // TODO: Add hover style
  .release__extras a {
    color: rgba(255, 255, 255, 0.6);
  }
}




/******* Features - Powerful */
#content_text-with-media_power {

  picture,
  img {
    box-shadow: none;
  }
}




/******** Use Cases - Enterprise */
.use-cases--enterprise__intro {
  color: palette(gray, white);
}




/******** Use Cases - Enterprise */
#content_highperformer-award {
  text-align: center;

  .award-logos {
    display: flex;
    justify-content: center;
  }

  .award-logo {
    text-align: center;

    @include media(s) {
      margin-right: 3rem;
    }

    margin-right: 1rem;

    &:last-of-type {
      margin-right: 0;
    }
  }

  .additional-badges {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    @include media(s) {
      margin-right: 3rem;
    }

    margin-right: 1rem;

    .award-logo:first-of-type {
      margin-right: 0;
    }
  }

  .award-text {
    display: inline-block;
    max-width: 650px;
    padding: 20px 42px 40px 42px;
    font-size: rem(fontsize(s));
    color: palette(gray, white);
    font-style: italic;

    @include media(s) {
      font-size: rem(fontsize(m));
    }

    &:before,
    &:after {
      content: '"';
      display: inline-block;
    }
  }
}




/******* Integrations */
.code-hosting-services,
.diff-tools {
  .inner-container {
    display: flex;
    align-items: center;

    @include media(xs) {
      flex-direction: column;
    }

    @include media(l) {
      flex-direction: row;

      &> :first-child {
        margin-right: 4*$base-spacing;
      }
    }
  }

  &__text {
    flex: 0 0 40%;
    max-width: 600px;
    margin-bottom: 30px;

    ul {
      list-style-type: disc;
      margin-top: 1rem;
      margin-left: 1.5rem;
    }

    li {
      margin-bottom: 1rem;
    }

    @include media(xs) {
      order: -1;
      text-align: center;
    }

    @include media(l) {
      order: unset;
      text-align: left;
    }
  }

  &__logos {
    margin: 30px auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;

    li {
      /* see overwriting rules for individual items */
      margin: 0 10px 10px 10px;
      font-size: rem(14px);
      text-align: center;

      @include media(xs) {
        width: 110px;
      }

      @include media(m) {
        width: 140px;
      }

      @include media(l) {
        width: 110px;
      }

      svg {
        width: 75px;
        max-width: 100%;
        max-height: 80px;
      }
    }
  }
}

#git-hosting-hero .code-hosting-services__logos {
  max-width: 768px;
}


.code-hosting-services {
  &__logos li {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    height: 150px;

    p {
      margin-top: $small-spacing;
    }
  }
}

.diff-tools {
  &__logos li {
    display: flex;
    height: 120px;
    align-items: center;
    justify-content: center;
  }
}



/******* Plain Content (e.g. on Imprint or Privacy Policy pages) */
.plain-content {
  h2 {
    margin-top: 3*$base-spacing;
  }

  h3 {
    margin-top: 2.25*$base-spacing;
  }

  h2+h3 {
    margin-top: 0.5*$base-spacing;
  }

  h4 {
    margin-top: 1.2*$base-spacing;
  }

  h3+h4 {
    margin-top: 0.5*$base-spacing;
  }

  ul {
    list-style-type: disc;
    list-style-position: inside;
    margin-bottom: $small-spacing;
  }
}

/******* Keyboard Shortcut style */
.kbd-shortcut {
  padding: .1em .4em;
  background-color: #123656;
  border-radius: .4em;
  font-size: 1rem;
  text-transform: uppercase;
  white-space: nowrap;
}
