/*******************
*  Tooltips
******************/

.tooltip-indicator {
  display: inline-block;
  position: relative;
  /* Example:
        width: 16px;
        height: 16px;
        top: 3px;
        margin-left: 5px;
        background-image: url(data:image/svg+xml;utf8,%3C%3Fxml%20version%3D%221.0%22%20encoding%3D%22utf-8%22%3F%3E%0D%0A%3C%21--%20Generator%3A%20Adobe%20Illustrator%2022.1.0%2C%20SVG%20Export%20Plug-In%20.%20SVG%20Version%3A%206.00%20Build%200%29%20%20--%3E%0D%0A%3Csvg%20version%3D%221.1%22%20id%3D%22Layer_1%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20xmlns%3Axlink%3D%22http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink%22%20x%3D%220px%22%20y%3D%220px%22%0D%0A%09%20viewBox%3D%220%200%2018%2018%22%20style%3D%22enable-background%3Anew%200%200%2018%2018%3B%22%20xml%3Aspace%3D%22preserve%22%3E%0D%0A%3Cstyle%20type%3D%22text%2Fcss%22%3E%0D%0A%09.st0%7Bfill%3A%23A8A7A7%3B%7D%0D%0A%3C%2Fstyle%3E%0D%0A%3Cpath%20class%3D%22st0%22%20d%3D%22M9%2C0C4%2C0%2C0%2C4%2C0%2C9s4%2C9%2C9%2C9s9-4%2C9-9S14%2C0%2C9%2C0z%20M9%2C16.9c-4.4%2C0-7.9-3.6-7.9-7.9S4.7%2C1.1%2C9%2C1.1s7.9%2C3.6%2C7.9%2C7.9%0D%0A%09S13.4%2C16.9%2C9%2C16.9z%22%2F%3E%0D%0A%3Cpath%20class%3D%22st0%22%20d%3D%22M9%2C7C8.5%2C7%2C8%2C7.5%2C8%2C8v5c0%2C0.5%2C0.5%2C1%2C1%2C1s1-0.5%2C1-1V8C10%2C7.5%2C9.5%2C7%2C9%2C7z%22%2F%3E%0D%0A%3Ccircle%20class%3D%22st0%22%20cx%3D%229%22%20cy%3D%225%22%20r%3D%221%22%2F%3E%0D%0A%3C%2Fsvg%3E%0D%0A);
        background-repeat: no-repeat;
        background-position: center center;
        cursor: help;
  */
}


#tooltip {
  display: none;
  position: absolute;
  z-index: 999;
  width: 250px;
  background-color: palette(gray, x-dark);
  border: 1px solid lighten(palette(gray, x-dark), 10%);
  border-radius:6px;
  color: palette(blue, 02);

  .tooltip {
    &__text {
      font-size: 15px;
      line-height: 20px;
      padding: 10px;
    }

    &__pointer {
      content: '';
      position: absolute;
      border-style: solid;
      border-radius:2px;
      border-color: palette(gray, x-dark) transparent;
      display: block;
      width: 32px;
      height: 14px;
      z-index: 1;

      &--center {
        left: calc(50% - 16px);
      }
      &--right {
        right: 6px;
      }
      &--left {
        left: 6px;
      }

      &--top {
        border-width: 0px 16px 16px 16px;
        top: -14px;
      }
      &--bottom {
        bottom: -14px;
        border-width: 16px 16px 0px 16px;
      }
    }
  }
}
