table {
  border-collapse: collapse;
  margin: $base-spacing 0;
  table-layout: fixed;
  text-align: left;
  width: 100%;
}

thead {
  line-height: $heading-line-height;
  vertical-align: bottom;
}

tbody {
  vertical-align: top;
}

tr {
  border-bottom: $base-border;
}

th {
  font-weight: 600;
}

th,
td {
  padding: $small-spacing $small-spacing $small-spacing 0;
}

.comparison-table {
  th, td {
    padding: 10px;
  }
  th {
    position: sticky;
    top: 50px;
    background-color: palette(blue, 10);
  }
  td:nth-of-type(1), th:nth-of-type(1) {
    text-align: left;
  }
  td, th {
    text-align: center;
    vertical-align: middle;
  }
  .row-highlight {
    color: #ffc600;
  }
}

#git-hosting-services {
  margin-top: 60px;
  .subheader {
    margin: 20px auto;
  }
}