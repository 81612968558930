figure {
  margin: 0;
}

img,
picture {
  margin: 0;
  max-width: 100%;
  height: auto;
}

// For Responsive YouTube embeds

.video-container { 
  position: relative; 
  padding-bottom: 56.25%;
  padding-top: 30px;
  height: 0;
  overflow: hidden; 
  margin-top: 2rem;
}

.video-container iframe, .video-container object, .video-container embed { 
  position: absolute;
  top: 0;
  left: 0;
  width: 100%; 
  height: 100%;
}