/************ Announcement Bar */
.announcement-bar {
  position: relative;
  overflow: hidden;
  margin-top: 52px;
  width: 100%;
  height: 70px;
  text-align: center;
  color: palette(blue, 09);

  a,
  a:hover {
    color: palette(blue, 09);
    text-decoration: none;
  }

  .inner-container {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: palette(yellow, base);
    padding: 1rem;

    &.hidden {
      margin-top: -70px;
      transition: margin-top 0.5s ease-out;
      transition-delay: 0.1s;
    }

    &.visible {
      visibility: visible;
      margin-top: 0px;
    }
  }

  &__icon {
    margin-right: 2*$base-spacing;

    @include media(xs) {
      display: none;
    }

    @include media(m) {
      display: block;
    }
  }

  &__text {
    @include media(xs) {
      font-size: rem(fontsize(s));
      line-height: rem(26px);
    }

    @include media(m) {
      font-size: rem(fontsize(l));
      line-height: $base-line-height;
    }

    display: inline-block;
    font-weight: fontweight(medium);
  }

  &__button {
    margin-left: $small-spacing;

    @include media(xs) {
      display: none;
    }

    @include media(m) {
      display: block;
    }

    .button {
      color: palette(blue, 09);
      border-color: palette(blue, 09);

      &:hover {
        color: palette(yellow, base);
        background-color: palette(blue, 09);
      }
    }
  }


  // Announcement: "Introductory Pricing"
  &--introductory-pricing {

    h3 {
      //  	  background:url(asset-url('assets/img/announcementHeader/win-logo_blackwhite.png')) top left no-repeat;
      //  	  background-size: 36px 36px;
    }
  }

}



/************ Special Pricing Bar */
.pricing-special-bar {
  text-align: center;
  background: palette(yellow, base);
  color: palette(blue, 09);
  font-weight: fontweight(bold);
  padding-top: 14px;
  padding-bottom: 14px;

  &__main {
    @include media(xs) {
      font-size: rem(fontsize(s));
      line-height: 26px;
    }

    @include media(l) {
      font-size: rem(fontsize(l));
      line-height: 60px;
    }

    .highlight {
      color: black;
      font-weight: 800;

      @include media(l) {
        font-size: rem(fontsize(xl));
      }
    }
  }

  &__sub {
    @include media(xs) {
      margin-top: $small-spacing;
      margin-bottom: 0;
      font-size: rem(fontsize(s));
      line-height: 26px;
    }

    @include media(l) {
      margin-top: 0;
      margin-bottom: $small-spacing;
      font-size: rem(fontsize(m));
      line-height: 30px;
    }
  }
}
