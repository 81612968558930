.overlay__container--download,
.overlay__container--trial-via-email,
.overlay__container--screencast,
.overlay__container--pricing,
.overlay__container--join-beta {
	display:none;
  align-items: center;
  justify-content: center;

  &.visible {
    display: flex;
    position:fixed;
    top:0;
    left:0;
    height:100vh;
    width:100vw;
    z-index: 500;
    @include media(xs) {
      background-color: palette(gray, white);
    }
    @include media(m) {
      background-color: rgba(0,0,0,0.7);
    }
  }
  
  .overlay__button--close {
    position: absolute;
    z-index: 10;
    top: 17px;
    right: 17px;
    display: block;
    width: 25px;
    height: 30px;
    cursor: pointer;
    
    span {
      position: relative;
      display: block;
      width: 100%;
      height: 8px;
      border-bottom: 3px solid palette(blue, 02);

      &:nth-of-type(1) {
        transform: rotate(45deg) translate(6px, 4px);
      }
      &:nth-of-type(2) {
        transform: rotate(-45deg) translate(-1px, -2px);
      }
    }
  }
}

.overlay__wrapper {
  position: relative;
  z-index: 999;
  border-radius: 6px;
  @include media(m){
    width: 770px;
    max-width: 90%;
  }
}

.overlay__content {
  $overlay-padding-x-desktop: 70px;
  $overlay-padding-x-mobile: 20px;
  
	display: none;
  background-color: palette(blue, 05);
  @include media(xs) {
    height: 100vh;
  }
  @include media(m) {
    height: auto;
    border: 1px solid palette(blue, 02);
  }

	&.hidden {
		display: none !important;
	}
  
  h2 {
    color: palette(gray, white);
    @include media(xs) {
      margin-bottom: 8px;
      font-size: rem(fontsize(l));
    }
    @include media(m) {
      margin-bottom: 0;
      font-size: rem(fontsize(xl));
    }
  }

  h4 {
    margin-bottom: 0;
    font-weight: fontweight(regular);
    @include media(xs) {
      font-size: rem(fontsize(m));
    }
    @include media(m) {
      font-size: rem(fontsize(l));
    }
  }
  
  .content__header {
    @include clearfix;
    display: flex;
    align-items: center;
    background-color: palette(blue, 09);

    @include media(xs) {
      padding: 20px $overlay-padding-x-mobile;
    }
    @include media(m) {
      padding: 40px $overlay-padding-x-desktop 35px $overlay-padding-x-desktop;
    }
    
    .header__icon {
      width: 80px;
      margin-right: 20px;
    }
  }

  .content__body {
    padding: 30px $overlay-padding-x-mobile 15px $overlay-padding-x-mobile;
    @include media(m) {
      padding: 45px $overlay-padding-x-desktop 45px $overlay-padding-x-desktop;
    }
    
    h2 {
      margin-bottom: 10px;
      .highlight {
        color: palette(blue, 02);
      }
    }
    
    label {
      font-weight: fontweight(regular);
      @include media(xs) {
        font-size: rem(fontsize(base));
        line-height: rem(24px);
      }
      @include media(m) {
        font-size: rem(fontsize(m));
        line-height: $base-line-height;
      }
    }
    
    input[type="checkbox"] {
      height: 17px;
    }

    .newsletter-form__consent {
      margin-top: 30px;
      
      label {
        font-size: rem(fontsize(base));
        line-height: rem(22px);
      }
    }
    
    .newsletter-form__signup {
      display: flex;
      margin-top: $small-spacing;
      
      input[type="text"] {
        margin: 0 10px 0 0;
        max-width: 300px;
      }
      input[type="submit"] {
        @include media(xs) {        
          background-color: palette(yellow, base);
          color: transparent;
          background-image: url(data:image/svg+xml;utf8,%3Csvg%20viewBox%3D%220%200%2029%2028%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpath%20d%3D%22M28.057.5l-3.52%2027.089-9.385-4.797-3.52%204.797v-7.144L23.364%206.366%208.981%2018.673l-9.038-5.37L28.057.5z%22%20fill%3D%22%23FFF%22%20fill-rule%3D%22evenodd%22%2F%3E%3C%2Fsvg%3E);
          background-repeat: no-repeat;
          background-position: center;
          background-size: 28px;
          width: 40px;
        }
        @include media(m) {
          background-color: transparent;
          color: palette(gray, white);
          background-image: none;
          width: auto;
          &:hover {
            background-color: palette(yellow, base);
            color: black;
          }
        }
      }
    }
  }
}



/**** Screencast */

.screencast__wrapper {
  position: relative;
  z-index: 999;
  border-radius: 6px;
  margin: 0px auto;
  padding-top: 70px;
  text-align:center;
  
  .screencast__player {

  }
}




/**** Trial via Email (-MOBILE-) */
.overlay__container--trial-via-email {
  .overlay__content {
    background: palette(gray, white);
    @include media(l) {
      padding: 30px;
    }
  }
  
  .content__header {
    margin-top: 20px;
    background-color: transparent;

    h2 {
      color: palette(blue, 09);
      font-size: rem(28px);

      @include media(s) {
        font-size: rem(fontsize(xl));
      }
    }
    h6 {
      color: palette(blue, 02);
      font-size: rem(22px);
      line-height: rem(32px);
    }
  }
  
  .content__body {
    padding-top: 0;
    color: palette(blue, 08);
    
    .newsletter-form__consent {
      label {
        font-size: rem(14px);
      }
    }
    
    .newsletter-form__signup {
      border-top: none;
      margin-top: 0;
    }
    
    .newsletter-form {
      input[name="email"] {
        float: left;
        height: 44px;
        box-shadow: none;
        border: 3px solid palette(blue, 02);
        border-radius: 0px;
        margin-right: 6px;
        margin-bottom: 0px;
        &::placeholder {
          color: palette(gray, base);
        }
      }
    
      input[type="submit"] {
        height: 44px;
        background-color: palette(yellow, base);
        color: transparent;
        background-image: url(data:image/svg+xml;utf8,%3Csvg%20viewBox%3D%220%200%2029%2028%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpath%20d%3D%22M28.057.5l-3.52%2027.089-9.385-4.797-3.52%204.797v-7.144L23.364%206.366%208.981%2018.673l-9.038-5.37L28.057.5z%22%20fill%3D%22%23FFF%22%20fill-rule%3D%22evenodd%22%2F%3E%3C%2Fsvg%3E);
        background-repeat: no-repeat;
        background-position: center;
        background-size: 28px;
        width: 40px;
      }
    
      .request-notice {
        clear: both;
        font-size: rem(13px);
        font-weight: normal;
      }
    }

    @include media(s) {
      padding-top: 30px;
    }
  }
}



/**** Trial Download + Signup */
.overlay__container--download {
  .overlay__content--1 {
		width: 100%;
    text-align:left;
  }

	.overlay__content--2 {
		width: 100%;
		text-align:left;
    background-image: url(asset-url('assets/img/giveaways/tower-shirts_cropped@2x.png'));
    background-repeat: no-repeat;
    background-position: right bottom;
    background-size: 270px;
    
    .content__body {      
      h3 {
        color: palette(gray, white);
        font-weight: fontweight(regular);
        margin-bottom: 1.5*$base-spacing;
      }
    
      .hint {
        margin-top: 1.5*$base-spacing;
        font-size: rem(fontsize(base));
        line-height: rem(22px);
      }
    }
	}
}
