html {
  font-size: fontsize(base);
  line-height: $base-line-height;
}

body {
  color: $base-font-color;
  font-family: var(--base-font-family);
  font-size: rem(fontsize(m));
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: var(--base-font-family);
  font-size: 1.25em;
  line-height: $heading-line-height;
  margin: 0 0 $small-spacing;
  color: palette(blue, 02);
}

h1 {
  font-size: rem(fontsize(xxl));
}

h2 {
  font-size: rem(fontsize(xl));
}

h3.subline {
  font-size: rem(fontsize(l));
  font-weight: fontweight(regular);
  color: white;
}

h4 {}

h5 {
  font-size: rem(fontsize(m));
  font-weight: fontweight(bold);
  margin: 0;
}

h6 {
  font-size: rem(fontsize(s));
  font-weight: fontweight(medium);
  margin: 0;
}

p {
  margin: 0 0 $small-spacing;

  &.subheader {
    text-align: center;
    font-size: rem(fontsize(l));
    line-height: rem(36px);
    max-width: 750px;
    margin-left: auto;
    margin-right: auto;
  }
}

a {
  color: $action-color;
  text-decoration-skip-ink: auto;

  &:hover {
    color: shade($action-color, 25%);
  }

  &:focus {
    outline: none;
    outline-offset: $focus-outline-offset;
  }
}

hr {
  border-bottom: $base-border;
  border-left: 0;
  border-right: 0;
  border-top: 0;
  margin: $base-spacing 0;
}

.font--white {
  color: white;
}

.font--blue-02 {
  color: palette(blue, 02);
}

.font--highlight {
  display: inline;
  border-bottom: 2px solid palette(blue, 02);
}

.text--centered {
  text-align: center;
}

.text--maxwidth {
  max-width: 650px;
  margin: 0px auto;
}

.feature-status {
  display: inline-block;
  border-radius: $base-border-radius;
  font-size: rem(14px);
  padding: 2px 10px;
  margin-right: $small-spacing;

  &:last-child {
    margin-right: 0;
  }

  &--ready {
    background: green;
    color: palette(gray, white);
  }

  &--coming {
    background: #666;
    color: palette(gray, white);
  }

  &--beta {
    background-color: #ffc600;
    color: black;
  }
}

.link-blue {
  color: white;
  text-decoration: none;
  border-bottom: 2px solid palette(blue, 02);

  &:hover {
    color: palette(blue, 02);
    border-bottom: 2px solid palette(blue, 02);
  }
}
