/******
  Newsletter
*/
#content__newsletter {
  form {
    max-width: 650px;
  }
  
  input[name="email"] {
    max-width: 450px;
  }
    
  .newsletter-form__groups,
  .newsletter-form__consent {
    margin-top: 1.5*$base-spacing;
    
    .group {
      margin-top: $small-spacing;
      color: palette(blue, 02);
    }
    
    input[type="checkbox"] {
      height: 17px;
    }
    
    label {
      display: inline;
      font-weight: normal;
    }
  }
  
  .newsletter-form__submit {
    display: flex;
    align-items: center;
    margin-top: $base-spacing;
  }
  
  .request-notice,
  .request-notice p {
    font-size: rem(fontsize(base));
  }
  
  ul {
    list-style-type:disc;
    list-style-position: inside;
  }
  
  .share-tweet {
    display: flex;
    justify-content: flex-start;
    @include media(xs) {
      flex-direction: column;
    }
    @include media(m) {
      flex-direction: row;
    }
    
    &__text {
      @include media(xs) {
        order: 2;
        margin: 20px 0 40px 0;
      }
      @include media(m) {
        order: 0;
        margin: 50px 30px 0 0;
      }
    }
    
    &__hint {
      margin-top: 40px;
      font-size: rem(16px);
    }
    
    &__visual {
      img {
        width: 440px;
      }
    }
  }
}
