/****************
* text-with-content
*/

.text-with-content {
  h2 {
    color: palette(blue, 02);
  }
  
  .testimonial {
    max-width: 700px;
    margin-left: auto;
    margin-right: auto;
  }
  
  &__content--shadow {
    img, 
    video {
      box-shadow: 0 20px 24px rgba(0, 0, 0, 0.6);
    }
  }

  video {
    transition: opacity 0.3s ease-in;
    &.loading {
      opacity: 0;
    }
    &.ready {
      opacity: 1;
    }
  }
  
  &--horizontal {
    .inner-container {
      display: flex;
      align-items: center;
      @include media(xs) {
        flex-direction: column;
      }
      @include media(m) {
        flex-direction: row;
        & > :first-child {
          margin-right: 4*$base-spacing;
        }
      }
    }
    
    .text-with-content__text {
      flex: 0 0 40%;
      @include media(xs) {
        order: -1;
        margin-bottom: 2.5*$base-spacing;
      }
      @include media(m) {
        order: unset;
      }
    }
  
    .text-with-content__content {
      width: 100%;
    
      img, svg {
        width: 100%;
        max-width: 500px;
      }
    }
  }
  
  
  &--vertical {
    .inner-container {
    }
    
    .text-with-content__text {
      text-align: center;
      margin-bottom: 2*$base-spacing;
      p {
        max-width: 700px;
        margin-left: auto;
        margin-right: auto;
      }
    }
  
    .text-with-content__content {
      width: 100%;
    
      img, svg, video {
        width: 100%;
        max-width: 100%;
      }
    }
  }
}
