/******* Footer */
footer {
  padding-top: 35px;
  padding-bottom: 20px;
  @include media(s) {
    border-top: 1px solid palette(blue, 08);
  }

  .copyright {
    margin-top: 40px;
    text-align: center;
    font-size: rem(12px);
    line-height: rem(18px);
    color: palette(gray, base);
    a {
      color: palette(gray, base);
      text-decoration: none;
    }
  }
  
  .navi-footer {
    @include media(xs) {
      
    }
    @include media(s) {
      display: flex;
      justify-content: space-between;
    }
    
    a {
      text-decoration: none;
      color: palette(gray, white);
    }
  }
  
  .navi-footer__group {
    font-size: rem(fontsize(s));
    label {
      margin: 0px;
    }
    .available-jobs-counter {
      display: inline-flex;
      justify-content: center;
      align-items: center;
      background-color: palette(blue, button);
      color: palette(blue, 09);
      height: 20px;
      width: 20px;
      border-radius: 50%;
      line-height: 20px;
      font-weight: 700;
    }
    
    @include media(xs) {
      width: 100%;
      
      h5 {
        border-top: 1px solid palette(blue, 08);
        padding-top: 18px;
        position: relative;
        &:after {
          content: "";
          display: block;
          width: 20px;
          height: 11px;
          position: absolute;
          top: 50%;
          right: 1rem;
          background-image: url(data:image/svg+xml;utf8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%2025%2013.99%22%3E%0D%0A%20%20%3Cpath%20d%3D%22M0%201A1%201%200%200%201%20.29.29a1%201%200%200%201%201.39%200L12.5%2011.18%2023.32.28a1%201%200%200%201%201.39%200%201%201%200%200%201%200%201.41L12.5%2014%20.29%201.69A1%201%200%200%201%200%201%22%20fill%3D%22%2351bbf1%22%2F%3E%0D%0A%3C%2Fsvg%3E);
          background-repeat: no-repeat;
          background-size: contain;
          transition: transform .12s ease-out;
        }
      }
      
      ul {
        max-height: 0px;
        overflow: hidden;
        transition: max-height 0.1s cubic-bezier(0.165, 0.84, 0.44, 1);
        margin: 8px 20px 0px 20px;
        line-height: 46px;
      }
      
      li {
        border-bottom: 1px solid palette(blue, 02);
        &:last-child {
          border-bottom: none;
        }
      }
      
      input[type=checkbox]:checked ~ ul {
        max-height: 999px;
        margin-bottom: 18px;
      }
      input[type=checkbox]:checked ~ label h5 {
        &:after {
          transform: rotate(180deg);
        }
      }
    }
    
    @include media(s) {
      width: auto;
      
      h5 {
        font-size: fontsize(l);
        border-top: none;
        padding-top: 0;
        &:after {
          background: none;
        }
      }
      
      ul {
        max-height: 999px;
        border-bottom: none;
        margin: 0 0 36px 0;
        line-height: $base-line-height;
      }
      
      li {
        border-bottom: none;
      }
    }
    
    input.footer-group__toggle {
      opacity: 0;
      display: block;
      height: 0px;
      width: 0px;
    }

  }
}
