@media print {
   h1, h2, h3, h4, h5, h6, p {
     color: black !important;
   }
   
   .nav-top,
   .announcement-bar,
   footer {
     display: none !important;
   }
}