/************ OS-Switch buttons */
.os-selection {
  .os-switch {
    display: inline-block;
    transition: none;
    line-height: 1.2em;
    text-decoration: none;
  }
  
  .os-switch__os-string {
    background-repeat: no-repeat;
  }
    
  // both-OS display
  &--both {
    .os-switch__os-string {
      padding-left: 30px;
      margin-left: 20px;
      background-size: 26px;
      background-position: 0px 4px;
    }
    
    .os-switch--windows.selectable .os-switch__os-string {
      background-image: url(asset-url('assets/img/icons/os-logo_windows--inactive.svg'));
      &:hover {
        background-image: url(asset-url('assets/img/icons/os-logo_windows--hover.svg'));
      }
    }
    .os-switch--windows.selected .os-switch__os-string {
      background-image: url(asset-url('assets/img/icons/os-logo_windows--active.svg'));
    }
    .os-switch--mac.selectable .os-switch__os-string {
      background-image: url(asset-url('assets/img/icons/os-logo_mac--inactive.svg'));
      &:hover {
        background-image: url(asset-url('assets/img/icons/os-logo_mac--hover.svg'));
      }
    }
    .os-switch--mac.selected .os-switch__os-string {
      background-image: url(asset-url('assets/img/icons/os-logo_mac--active.svg'));
    }
    .selectable .os-switch__os-string {
      color: #D8D9D8;
      transition: color 150ms ease;
      &:hover {
        color: #ffc600;
      }
    }
    .selected .os-switch__os-string {
      font-weight: 600;
      text-decoration: none;
      color: #ffffff;
      border-bottom: 6px solid #00caf4;
    }
  }
  
  // single-OS display
  &--single {
    .os-switch__os-string {
      background-image: none;
      // padding-left: 26px;
      // margin-left: 6px;
      // background-size: 20px;
    }
    
    .os-switch--windows .os-switch__os-string {
      // background-position: 0px -79px;
    }
    .os-switch--mac .os-switch__os-string {
      // background-position: 0px -116px;
    }
  }
}
