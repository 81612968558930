$_button-background-color: $action-color;

.button,
[type=button],
[type=reset],
[type=submit],
button {
  appearance: none;
  background-color: $_button-background-color;
  border: 3px solid $_button-background-color;
  border-radius: 6px;
  color: palette(yellow, text);
  cursor: pointer;
  display: inline-block;
  font-family: var(--base-font-family);
  font-size: fontsize(m);
  font-weight: 600;
  line-height: rem(36px);
  padding: 0 2rem;
  text-align: center;
  text-decoration: none;
  user-select: none;
  vertical-align: middle;
  white-space: nowrap;

  &:hover {
    background-color: palette(yellow, hover);
    cursor: pointer;
  }

  &:focus {
    outline: $focus-outline;
    outline-offset: $focus-outline-offset;
  }

  &:disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }
}


/************ Button variations */
.button--outlined:hover {
  background-color: palette(yellow, hover);
  border-color: palette(yellow, hover);
}

.button--primary {}

.button--secondary {
  background-color: palette(blue, button);
  border-color: palette(blue, button);
  color: palette(blue, 09);

  &:hover {
    background-color: palette(blue, hover);
    border-color: palette(blue, hover);
  }
}

.button--twitter {
  padding-left: 58px;
  background-color: palette(blue, button);
  background-image: url(asset-url('assets/img/icons/twitter-icon.svg'));
  background-repeat: no-repeat;
  background-size: 26px;
  background-position: 12px center;
}


/************ Button wrapper */
.button__wrap {
  text-align: center;

  @include media(m) {
    display: inline-block;
  }

  .button {
    display: inline-block;
    min-width: 270px;
    margin-top: 20px;
  }

  .note {
    display: inline-block;
    margin-top: 4px;
    font-size: rem(fontsize(base));
    color: palette(gray, white);
    text-decoration: none;

    &:hover {
      color: palette(yellow, base);
    }

    a {
      color: palette(gray, white);

      &:hover {
        color: palette(yellow, base);
      }
    }
  }
}
