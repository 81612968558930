/****************
* text-with-media
*/

@keyframes slider-animation-hide-right {
  0% {
    opacity: 1;
    transform: scale(1.0) translateX(0px); }
  99% {
    opacity: 0;
    transform: scale(0.95) translateX(120px); }
  100% {
    opacity: 0;
    transform: scale(1.0) translateX(0px); }
}
@keyframes slider-animation-hide-left {
  0% {
    opacity: 1;
    transform: scale(1.0) translateX(0px); }
  99% {
    opacity: 0;
    transform: scale(0.95) translateX(-120px); }
  100% {
    opacity: 0;
    transform: scale(1.0) translateX(0px); }
}
@keyframes slider-animation-show {
  0% {
    opacity: 0; 
    transform: scale(0.95); }
  100% {
    opacity: 1; 
    transform: scale(1.0); }
}

.content-slider {
  %content-slider__control {
    position:absolute;
    top:calc(50%);
    width: 14px;
    height: 25px;
    z-index: 10;
    cursor: pointer;
  }
  &__control--previous {
    @extend %content-slider__control;
    left: 12px;
  }
  &__control--next {
    @extend %content-slider__control;
    right: 12px;
  }
  
  &__navigation {
    position: absolute;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
    text-align: center;
  }
  &__navigation-item {
    display: inline-block;
    cursor: pointer;
    width: 9px;
    height: 9px;
    border-radius: 50%;
    background-color: palette(blue, 02);
    margin-right: 10px;
    &:last-child { margin-right: 0px; }
    &.active {
      background-color: palette(yellow, base);
    }
  }

  &__item {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    opacity: 0; // important for animation
    
    &.hidden-right {
      animation: slider-animation-hide-right 0.7s;
      animation-fill-mode: forwards;
    }
    &.hidden-left {
      animation: slider-animation-hide-left 0.7s;
      animation-fill-mode: forwards;
    }
    &.active {
      animation: slider-animation-show 1.0s;
      animation-fill-mode: forwards;
      animation-delay: 0.4s;
    }
  }
}
