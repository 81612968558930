@keyframes submenu-show {
  0% {
    height: auto;
    width: 300px;
    opacity: 0;
    padding-top: 20px;
  }

  100% {
    height: auto;
    width: 300px;
    opacity: 1;
    padding-top: 38px;
  }
}

@keyframes submenu-hide {
  0% {
    height: auto;
    width: 300px;
    opacity: 1;
    padding-top: 38px;
  }

  99% {
    height: auto;
    width: 300px;
    opacity: 0;
    padding-top: 20px;
  }

  100% {
    height: 0px;
    width: 0px;
    opacity: 0;
    padding-top: 20px;
  }
}
