/****************
* Media Query / Breakpoints
*/
@mixin media ($size-name) {
  @if(not map-has-key($mediaqueries, $size-name)){
    @warn "mediaquery of name '#{$size-name}' is not defined!";
  }

  $query: map-get($mediaqueries, $size-name);
  
  // --> output
  @media #{$query} {
    @content;
  }
}


/****************
* Retina Screens
*/
@mixin retina {
  @media
    only screen and (-webkit-min-device-pixel-ratio: 2),
    only screen and (   min--moz-device-pixel-ratio: 2),
    only screen and (     -o-min-device-pixel-ratio: 2/1),
    only screen and (        min-device-pixel-ratio: 2),
    only screen and (                min-resolution: 192dpi),
    only screen and (                min-resolution: 2dppx) {
      @content;
  }
}


/****************
* Color Themes
*/
@mixin color-theme ($theme-name, $font-color, $bg-color...) {
  .theme--#{$theme-name} {
    color: #{$font-color};
    @if length($bg-color) == 2 {
      background: linear-gradient(to bottom, nth($bg-color, 1) 0%, nth($bg-color, 1) 92%, nth($bg-color, 2) 92%, nth($bg-color, 2) 100%);    
    } @else if length($bg-color) == 1 {
      background-color: #{$bg-color};
    }
  }
  
}


/****************
* Clearfix
*/
@mixin clearfix {
  &::after {
    clear: both;
    content: "";
    display: block;
  }
}
