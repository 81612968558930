.modal-view {
  background: var(--background-primary);
  border: 0;
  border-radius: 10px;
  box-shadow:
    0 0 0 1px rgb(0 0 0 / 0.2),
    0 20px 40px rgb(0 0 0 / 0.4);
  padding: 0;
  width: 770px;
}

.modal-view::backdrop {
  background-color: rgba(0, 0, 0, 0.3);
}

@keyframes modal-view-reveal {
  0% {
    opacity: 0;
    transform: translateY(-20px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.modal-view-reveal {
  animation: modal-view-reveal 0.3s cubic-bezier(0.4, 0, 0.2, 1) forwards;
}

@keyframes modal-view-hide {
  0% {
    opacity: 1;
    transform: translateY(0);
  }

  100% {
    opacity: 0;
    transform: translateY(-20px);
  }
}

.modal-view-hide {
  animation: modal-view-hide 0.3s cubic-bezier(0.4, 0, 0.2, 1) forwards;
}

.modal-close {
  background: rgb(255 255 255 / 0.25) url('../img/icons/x-mark.svg') center no-repeat;
  border-radius: 50%;
  border: 0;
  width: 36px;
  height: 36px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  right: 12px;
  text-indent: -9999px;
  top: 12px;
}

.modal-close:hover {
  background-color: rgb(255 255 255 / 0.5);
}

.modal-big-header {
  padding: 47px 50px 53px;
}

.modal-big-header::after {
  bottom: 0;
  border-radius: 10px;
  content: "";
  box-shadow:
    inset 0 1px 0 rgb(255 255 255 / 0.1),
    inset 0 0 0 1px rgb(255 255 255 / 0.05);
  left: 0;
  right: 0;
  pointer-events: none;
  position: absolute;
  top: 0;
}

.modal-title {
  color: #fff;
  font-size: 36px;
  font-weight: 700;
  line-height: 44px;
  margin: 0;
}

.modal-description {
  color: var(--foreground-highlight);
  font-size: 24px;
  line-height: 30px;
  margin: 0;
}

.modal-title + .modal-description {
  margin-top: 8px;
}

.modal-content {
  background: var(--background-tertiary);
  box-shadow: inset 0 1px 0 rgb(255 255 255 / 0.1);
}

.modal-content h2 {
  color: #fff;
}

/* ## After download modal */

#after-download-modal .modal-big-header {
  position: relative;
  padding-left: 160px;
}

#after-download-modal .modal-app-icon {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  left: 50px;
  top: 46px;
}

@keyframes app-icon-bounce {

  25%,
  75%,
  100% {
    transform: translateY(0);
    animation-timing-function: cubic-bezier(0.2, 1, 0.8, 1);
  }

  0% {
    transform: translateY(-45px);
    animation-timing-function: cubic-bezier(0.8, 0, 1, 1);
  }

  50% {
    transform: translateY(-30px);
    animation-timing-function: cubic-bezier(0.8, 0, 1, 1);
  }

  87% {
    transform: translateY(-15px);
    animation-timing-function: cubic-bezier(0.8, 0, 1, 1);
  }
}

/* 0 _ 25 ^ 50 _ 75 ^ 87.5 _ 100 */

@keyframes app-icon-fade-in {
  0% {
    opacity: 0;
  }

  20% {
    opacity: 1;
    animation-timing-function: ease-out;
  }
}

#after-download-modal .modal-app-icon img {
  display: block;
  height: 85px;
  margin-bottom: 6px;
  opacity: 0;
  width: 85px;
}

#after-download-modal .modal-app-icon-loaded img {
  animation:
    app-icon-bounce 2s,
    app-icon-fade-in 2s;
  opacity: 1;
}

#after-download-modal .modal-app-icon-name {
  background: rgb(255 255 255 / 0.25);
  border-radius: 10px;
  display: block;
  color: #fff;
  font-size: 13px;
  line-height: 16px;
  padding: 1px 9px 3px;
  text-align: center;
}

.after-download {
  padding: 40px 50px;
}

.after-download h2 {
  font-size: 36px;
  font-weight: 700;
  line-height: 44px;
  margin: 0 0 40px;
}

.after-download .newsletter-form {
  max-width: 100%;
  color: #fff;
}

.after-download .newsletter-form__groups {
  font-size: 20px;
  line-height: 24px;
  margin-bottom: 32px;
}

.after-download .newsletter-form__groups .row {
  margin-bottom: 16px;
}

.after-download label {
  font-weight: 400;
}

.after-download .newsletter-form__consent label {
  font-size: 16px;
  line-height: 20px;
}

.after-download .newsletter-form__consent a {
  color: var(--foreground-highlight-alt);
  text-decoration: underline;
}

.after-download .newsletter-form__consent a:hover {
  color: var(--foreground-highlight-alt-hover);
}

.after-download .newsletter-form__groups label,
.after-download .newsletter-form__consent label {
  display: block;
  padding-left: 30px;
}

.after-download .newsletter-form__groups input[type=checkbox],
.after-download .newsletter-form__consent input[type=checkbox] {
  float: left;
  height: 12px;
  margin-left: -21px;
  margin-top: 6px;
  width: 12px;
}

.after-download .newsletter-form__consent input[type=checkbox] {
  margin-top: 3px;
}

.after-download .newsletter-form__signup {
  background: transparent;
  height: auto;
  margin-top: 24px;
  max-width: 100%;
}

.after-download .newsletter-form__signup input[type=email] {
  border: 1px solid #DCDCDC;
  border-radius: 3px;
  box-shadow: none;
  display: inline-block;
  font-size: 16px;
  height: auto;
  line-height: 20px;
  margin-bottom: 0;
  padding: 10px;
  width: 300px;
  vertical-align: top;
}

.after-download .newsletter-form__signup input[type=email]::placeholder {
  color: #B3B3B3;
}

.after-download .newsletter-form__signup input[type=submit] {
  background: linear-gradient(#FFCE22, #F2BC00);
  border: 0px;
  border-radius: 6px;
  box-shadow:
    0 2px 4px rgb(59 35 0 / 0.2),
    0 0 0 0.5px rgb(59 35 0 / 0.5),
    inset 0 0.5px rgb(255 244 153 / 0.3);
  color: #3B2300;
  display: inline-block;
  font-size: 20px;
  font-weight: 600;
  line-height: 24px;
  position: static;
  height: auto;
  padding: 9px 25px;
  transition: none;
  vertical-align: top;
  width: auto;
}

.after-download .newsletter-form .request-notice {
  margin-top: 8px;
  padding: 0
}

.after-download .request-notice.error {
  color: #ff4b00;
}

.after-download .request-notice:empty {
  display: none;
}

.after-download .request-notice p {
  margin: 0;
}

/* ## After download modal */

.after-subscribe {
  background-image: url("../img/giveaways/tower-shirts_cropped@2x.png");
  background-position: bottom right;
  background-repeat: no-repeat;
  background-size: 270px;
  padding: 40px 50px;
}

.after-subscribe h2 {
  font-size: 24px;
  line-height: 30px;
  font-weight: 400;
  margin: 0 0 40px;
}

.after-subscribe h2 strong {
  display: block;
  font-weight: 700;
}

.after-subscribe-share {
  margin-bottom: 40px;
}

.after-subscribe-share .button {
  background:
    url("../img/icons/twitter-icon.svg") 16px center/24px no-repeat,
    linear-gradient(#85E5FF, var(--foreground-highlight));
  border: 0px;
  border-radius: 6px;
  box-shadow:
    0 2px 4px rgb(0 46 59 / 0.2),
    0 0 0 0.5px rgb(0 46 59 / 0.5),
    inset 0 0.5px rgb(182 239 255 / 0.3);
  color: #002E3B;
  font-size: 20px;
  font-weight: 600;
  line-height: 24px;
  padding: 9px 25px 9px 50px;
}

.after-subscribe-share .button:hover {
  background:
    url("../img/icons/twitter-icon.svg") 16px center/24px no-repeat,
    linear-gradient(#94E7FE, #61DCFE);
  text-decoration: none;
}

.after-subscribe-winners {
  color: #fff;
  font-size: 16px;
  line-height: 20px;
}

.after-subscribe-winners a {
  color: var(--foreground-highlight-alt);
  text-decoration: underline;
}

.after-subscribe-winners a:hover {
  color: var(--foreground-highlight-alt-hover);
}

/* ## Trial via email */

.trial-via-email label {
  font-weight: 400;
  font-size: 17px;
  line-height: 22px;
}

.trial-via-email .newsletter-form__groups {
  margin-bottom: 0;
}

.trial-via-email .newsletter-form__groups .row {
  margin-bottom: 8px;
}

.trial-via-email .newsletter-form__groups label,
.trial-via-email .newsletter-form__consent label {
  display: block;
  padding-left: 26px;
}

.trial-via-email .newsletter-form__groups input[type=checkbox],
.trial-via-email .newsletter-form__consent input[type=checkbox] {
  float: left;
  height: 12px;
  margin-left: -21px;
  margin-top: 6px;
  width: 12px;
}

.trial-via-email .newsletter-form__consent input[type=checkbox] {
  margin-top: 3px;
}

.trial-via-email .newsletter-form__consent label {
  font-size: 14px;
  line-height: 18px;
}

.trial-via-email .newsletter-form__consent a {
  color: var(--foreground-highlight-alt);
  font-weight: 400;
  text-decoration: underline;
}

.trial-via-email .newsletter-form__consent a:hover {
  color: var(--foreground-highlight-alt-hover);
}

.trial-via-email .newsletter-form__signup {
  background: transparent;
  height: auto;
  margin-top: 8px;
  max-width: 350px;
  position: relative;
}

.trial-via-email .newsletter-form__signup input[type=email] {
  background: #fff;
  border: 1px solid #DCDCDC;
  border-radius: 3px;
  box-sizing: border-box;
  box-shadow: none;
  color: #000;
  display: inline-block;
  font-size: 16px;
  font-weight: 400;
  height: auto;
  line-height: 20px;
  margin-bottom: 0;
  padding: 8px 10px;
  vertical-align: top;
  width: calc(min(100% - 50px, 300px));
}

.trial-via-email .newsletter-form__signup input[type=email]::placeholder {
  color: #B3B3B3;
}

.trial-via-email .newsletter-form__signup input[type=submit] {
  background:
    url("../img/icons/send-email.svg") center/24px no-repeat,
    linear-gradient(#FFCE22, #F2BC00);
  border: 0px;
  border-radius: 6px;
  box-shadow:
    0 2px 4px rgb(59 35 0 / 0.2),
    0 0 0 0.5px rgb(59 35 0 / 0.5),
    inset 0 0.5px rgb(255 244 153 / 0.3);
  color: #3B2300;
  display: inline-block;
  font-size: 20px;
  font-weight: 600;
  height: 38px;
  line-height: 24px;
  position: absolute;
  padding: 0;
  right: 0;
  text-indent: -9999px;
  top: 0;
  transition: none;
  vertical-align: top;
  width: 42px;
}

.trial-via-email .newsletter-form .request-notice {
  margin-top: 8px;
  padding: 0
}

.trial-via-email .request-notice.error {
  color: #ff4b00;
}

.trial-via-email .request-notice:empty {
  display: none;
}

.trial-via-email .request-notice p {
  margin: 0;
}

/* ## Mobile */

@media (max-width: 900px) {
  .modal-big-header {
    padding: 16px;
  }

  .modal-title {
    color: #fff;
    font-size: 28px;
    font-weight: 700;
    line-height: 34px;
    margin: 0;
  }

  .modal-description {
    color: var(--foreground-highlight);
    font-size: 17px;
    font-weight: 500;
    line-height: 22px;
    margin: 0;
  }

  .modal-title + .modal-description {
    margin-top: 8px;
  }

  .modal-content {
    color: #fff;
    background: var(--background-tertiary);
    box-shadow: inset 0 1px 0 rgb(255 255 255 / 0.1);
    padding: 16px;
  }

  .modal-content h2 {
    color: #fff;
  }
}
