/****************
* Testimonials
*/

// layout: 'content-slider'
.testimonials--content-slider{
  overflow: hidden;
  background: linear-gradient(to bottom, palette(blue, 05) 0%, palette(blue, 05) 56px, palette(blue, 08) 56px, palette(blue, 08) 100%);
  text-align: center;
  color: palette(blue, 02);

  .inner-container {
    position:relative;
    min-height: 415px;
  }

  .content-slider {
    .testimonial__image {
      img {
        border-radius: 50%;
        width: 112px;
        height: 112px;
      }
    }
  
    .testimonial__name {
      display: block;
      font-size: rem(fontsize(base));
      line-height: rem(20px);
      color: palette(gray, white);
    }

    .testimonial__company {
      font-size: rem(fontsize(base));
      line-height: rem(20px);
    }

    .testimonial__text {
      display: inline-block;
      max-width: 550px;
      padding: 20px 42px 40px 42px;
      font-size: rem(fontsize(s));
      color: palette(gray, white);
      font-style: italic;
      @include media(s) {
        font-size: rem(fontsize(m));
      }
      &:before, &:after {
        content: '"';
        display: inline-block;
      }
    }
  }
}



// layout: 'fullwidth'
.testimonials--fullwidth {
  .testimonial {
    display: flex;
    margin-bottom: 3*$base-spacing;
    @include media(xs) {
      flex-direction: column;
    }
    @include media(m) {
      flex-direction: row;
      align-items: center;
    }
    
    .text {
      @include media(xs) {
        order: -1;
      }
      @include media(m) {
        order: unset;
      }
      font-size: rem(24px);
      line-height: rem(32px);
      font-style: italic;
      color: palette(gray, white);
      border-left: 3px solid palette(blue, 02);
      padding-left: 1.2*$base-spacing;
      margin-left: $small-spacing;
      &:before, &:after {
        content: '"';
        display: inline-block;
      }
    }
    
    .info {
      @include media(xs) {
        margin-left: $small-spacing;
        margin-top: $base-spacing;
      }
      @include media(m) {
        margin-left: 0;
        margin-top: 0;
        min-width: 250px;
      }
      display: flex;
      align-items: center;
      
      .image {
        margin-right: $base-spacing;
        flex: 0 0 70px;
        height: 70px;
        img {
          border-radius: 50%;
          width: 100%;
        }
      }
  
      .person {
        line-height: rem(24px);
      }
    
      .name {
        font-size: rem(fontsize(s));
        color: palette(gray, white);
      }
    
      .company {
        font-size: rem(fontsize(base));
      }
    }
    
    &:last-child {
      margin-bottom: 0px;
    }
  }
}



// layout: 'halfwidth'
.testimonial--halfwidth {
  display: flex;
  flex-direction: column;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 4*$base-spacing;
  
  .text {
    font-size: rem(fontsize(s));
    line-height: rem(24px);
    font-style: italic;
    color: palette(gray, white);
    margin-bottom: 1.25*$base-spacing;
    &:before, &:after {
      content: '"';
      display: inline-block;
    }
  }
  
  .info {
    display: flex;
    align-items: center;
  
    .image {
      width: 70px;
      height: 70px;
      img {
        border-radius: 50%;
        width: 70px;
      }
    }

    .person {
      font-size: rem(fontsize(base));
      line-height: rem(20px);
      color: palette(blue, 02);
    }
  
    .name {
      color: palette(gray, white);
    }
  
    .company {
    
    }
  }
  
  &:nth-child(odd) {
    .text {
      text-align: left;
      border-left: 3px solid palette(blue, 02);
      padding-left: $base-spacing;
    }
    .info {
      text-align: left;
      .image {
        order: 0;
        margin-right: $base-spacing;
      }
    }
  }
  
  &:nth-child(even) {
    align-items: flex-end;
    .text {
      text-align: right;
      border-right: 3px solid palette(blue, 02);
      padding-right: $small-spacing;
    }
    .info {
      text-align: right;
      .image {
        order: 2;
        margin-left: $base-spacing;
      }
    }
  }
  
  &:last-child {
    margin-bottom: 0px;
  }
}



// layout: 'mini'
.testimonial--mini {
  font-size: rem(fontsize(base));
  line-height: rem(20px);
  color: palette(blue, 02);
  margin-top: rem(72px);

  img {
    float: left;
    width: 70px;
    border-radius: 50%;
  }

  .testimonial__text {
    margin-left: 90px;
    &:before, &:after {
      content: '"';
      display: inline-block;
    }
  }
  
  .testimonial__person {
    margin-left: 90px;
    margin-top: 10px;      
    a {
      text-decoration: none;
    }
  }

  .testimonial__name {
    color: palette(gray, white);
  }

  .testimonial__company {
    &:before {
      content: '-';
      display: inline-block;
      margin: 0px 8px;
    }
  }
}

/***  Grid for Stacked Testimonials */

.testimonial-grid-3x {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  .testimonial {
    flex: 0 1 300px;
  }
  .testimonial__text {
    color: palette(gray, white);
  }
  .testimonial__name {
    color: palette(blue, 02); 
  }
}

/***  Style for Testimonials in Social Proof Stack */
#content_social-proof .outer-container--padding {
  padding: 55px 0 15px;
}

.testimonial--spstack {
  text-align: center;
  font-style: italic;
  font-size: rem(fontsize(base));
  line-height: rem(20px);
  img {
    width: 70px;
    border-radius: 50%;
  }
  blockquote, .company {
    color: palette(blue, 02);
  }
}