/****************
* Block Lists
*/
.block-list {
  color: palette(gray, white);
  
  .subheader {
    color: palette(blue, 02);
    font-size: fontsize(l);
    @include media(xs) {
      margin-bottom: 40px;
    }
    @include media(l) {
      margin-bottom: 80px;
    }
  }
  
  &__items {
    display: flex;
    flex-wrap: wrap;

    .item {
      flex: 1 1 400px;
      padding: 0px gutter(s);
      margin-bottom: 40px;
  
      &__image {
        float: left;
        @include media(xs) {
          width: 45px;
        }
        @include media(l) {
          width: 100px;
        }
      }
      &__text {
        @include media(xs) {
          margin-left: 65px;
        }
        @include media(l) {
          margin-left: 140px
        }
      }
      &__headline {
        @include media(xs) {
          font-size: fontsize(m);
          margin-bottom: 4px;
        }
        @include media(l) {
          font-size: fontsize(l);
        }
      }
    }
  }
  
  .button__wrap {
    width: 100%;
    text-align:center;
    margin: 60px 0px 40px 0px;
  }
  
  &.theme--05 {
    h2 {
      color: palette(blue, 02); }
    .subheader {
      color: palette(gray, white); }
  }
}
