.nav-top-common {
  align-items: center;
  // TODO: Replace the gradient with CSS variable!
  background: var(--nav-background);
  border-radius: 26px;
  box-shadow:
    inset 0 0 0 1px rgba(255, 255, 255, 0.05),
    0 10px 20px rgba(0, 0, 0, 0.4);
  display: flex;
  flex-direction: row;
  height: 52px;
  left: 50%;
  margin: 40px 0 40px -440px;
  outline: 1px solid rgba(0, 0, 0, 0.8);
  padding: 0;
  position: fixed;
  top: 0;
  width: 880px;
  z-index: 201;
}

.nav-top-common li::before {
  display: none;
}

.nav-top-common li {
  display: block;
  margin: 0;
}

.nav-top-common a,
.nav-top-common a:hover {
  text-decoration: none;
}

.nav-top-logo {
  left: 50%;
  margin: 1px 0 0 -64px;
  position: absolute;
}

.nav-top-logo a {
  background: url(asset-url('assets/img/illustrations/tower-header-logo-royal.svg'));
  display: block;
  height: 34px;
  overflow: hidden;
  text-indent: 9999px;
  width: 127px;
}

.nav-top-show {
  display: none;
}

.nav-top-primary {
  display: contents;
}

.nav-top-primary > li {
  position: relative;
}

.nav-top-primary > li:first-child {
  margin-left: 12px;
}

.nav-top-primary > li > a {
  border-radius: 15px;
  color: var(--nav-link-color);
  display: block;
  font-weight: 500;
  font-size: 14px;
  line-height: 30px;
  padding: 0 15px;
}

.nav-top-primary > li:hover > a {
  color: #fff;
}

.nav-top-primary > li.nav-top-primary-active > a {
  background: rgb(90 122 153 / 0.33);
  color: #fff;
}

.nav-top-primary-spacer {
  flex-grow: 1;
}

@keyframes nav-top-common-secondary-show {
  0% {
    margin-top: -5px;
    opacity: 0;
  }

  100% {
    margin-top: 0;
    opacity: 1;
  }
}

.nav-top-secondary {
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  background: var(--nav-secondary-background);
  border-radius: 10px;
  box-shadow:
    inset 0 0 0 1px rgba(255, 255, 255, 0.05),
    0 0 0 1px rgba(0, 0, 0, 0.4),
    0 2px 6px rgba(0, 0, 0, 0.25);
  display: none;
  left: 50%;
  margin-left: -150px;
  padding: 5px;
  position: absolute;
  top: 30px;
  width: 300px;
  z-index: 202;
}

@media (max-width: 1090px) {

  /* When screen gets tighter, the menu can be clipped, so let's align its left
     to the primary item's left */
  .nav-top-secondary#nav-top-features {
    left: 0;
    margin-left: 0;
  }
}

.nav-top-primary > li:hover > .nav-top-secondary {
  display: block;
  animation: nav-top-common-secondary-show;
  animation-duration: 0.2s;
  animation-fill-mode: forwards;
}

.nav-top-secondary li {
  font-size: 14px;
}

.nav-top-secondary a {
  color: var(--nav-secondary-link-color);
  display: block;
  font-size: 17px;
  font-weight: 500;
  line-height: 20px;
  padding: 12px 11px;
}

.nav-top-secondary a:hover {
  background-color: var(--nav-secondary-link-hover-background);
  border-radius: 5px;
  color: #fff;
}

.nav-top-secondary-title {
  display: block;
  padding-bottom: 3px;
}

.nav-top-secondary a .nav-top-secondary-subline {
  color: #B8EAF8;
  display: block;
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
}

.nav-top-secondary-new-label {
  background: url(asset-url('assets/img/icons/nav-new-label.svg'));
  display: inline-block;
  height: 12px;
  width: 27px;
}

#nav-top-undo,
#nav-top-dragndrop,
#nav-top-integrations,
#nav-top-stacked-prs,
#nav-top-videos,
#nav-top-book,
#nav-top-cheatsheets,
#nav-top-webinar,
#nav-top-firstaid,
#nav-top-advanced {
  background-image: url(asset-url('assets/img/icons/nav-icons.svg'));
  background-repeat: no-repeat;
  padding-left: 63px;
}

#nav-top-undo {
  background-position: 9px 10px;
}

#nav-top-dragndrop {
  background-position: 9px -70px;
}

#nav-top-integrations {
  background-position: 9px -150px;
}

#nav-top-stacked-prs {
  background-position: 9px -230px;
}

#nav-top-videos {
  background-position: -391px 10px;
}

#nav-top-book {
  background-position: -391px -70px;
}

#nav-top-cheatsheets {
  background-position: -391px -150px;
}

#nav-top-webinar {
  background-position: -391px -230px;
}

#nav-top-firstaid {
  background-position: -391px -310px;
}

#nav-top-advanced {
  background-position: -391px -390px;
}

.nav-top-secondary .nav-top-secondary-seperator {
  background: var(--nav-secondary-seperator-color);
  height: 1px;
  margin: 5px 11px;
}

.nav-top-common .nav-top-download {
  margin: 0 10px 0 15px;
}

.nav-top-download a {
  background: var(--nav-download-background);
  border-radius: 16px;
  box-shadow:
    0 1px 4px var(--background-primary),
    inset 0 0 0 1px rgba(255, 255, 255, 0.25),
    inset 0 1px rgba(255, 255, 255, 0.2);
  color: #fff;
  display: block;
  font-size: 14px;
  font-weight: 600;
  line-height: 32px;
  padding: 0 16px;
  text-shadow: 0 1px 1px rgb(7 27 46 / 0.5);
}

.nav-top-download a:hover {
  background: var(--nav-download-hover-background);
}

@media (prefers-contrast: more) {
  .nav-top-download a {
    background: #96E9FF;
    box-shadow: 0 1px 4px var(--background-primary);
    color: #02101C;
    text-shadow: none;
  }

  .nav-top-download a:hover {
    background: #C5F2FF;
  }
}

@media (max-width: 900px) {
  .nav-top-common {
    border-radius: 0;
    box-shadow:
      inset 0 -1px 0 rgba(255, 255, 255, 0.05),
      0 10px 20px rgba(0, 0, 0, 0.4);
    display: block;
    height: auto;
    margin: 0;
    left: 0;
    width: 100%;
    height: 52px;
    overflow: hidden;
  }

  .nav-top-common.active {
    height: 100vh;
    overflow: scroll;
    transition: height 0.2s ease-out;
  }

  .nav-top-logo {
    top: 9px;
  }

  .nav-top-show {
    background: url(asset-url('assets/img/illustrations/nav-burger-royal.svg')) no-repeat center;
    display: block;
    height: 52px;
    left: 0;
    position: absolute;
    overflow: hidden;
    text-indent: 9999px;
    top: 0;
    width: 64px;
  }

  .nav-top-common.active .nav-top-show {
    background-image: url(asset-url('assets/img/illustrations/nav-burger-close-royal.svg'));
  }

  .nav-top-primary {
    display: none;
    flex-direction: column;
    margin: 0;
    padding: 52px 0 32px;
  }

  .nav-top-common.active .nav-top-primary {
    display: flex;
  }

  .nav-top-primary > li:first-child {
    margin-left: 0;
  }

  .nav-top-primary > li > a {
    font-size: 17px;
    line-height: 20px;
    padding: 12px 32px;
  }

  .nav-top-secondary {
    backdrop-filter: none;
    -webkit-backdrop-filter: none;
    background: transparent;
    border-radius: 0;
    box-shadow: none;
    display: block;
    left: 0;
    margin: 0 0 16px;
    padding: 0;
    position: static;
    top: 0;
    width: auto;
  }

  .nav-top-primary > li:hover > .nav-top-secondary {
    animation: none;
  }

  .nav-top-secondary a {
    color: var(--nav-link-color);
    padding-right: 32px;
    padding-left: 48px;
  }

  .nav-top-secondary a:hover {
    background: transparent;
  }

  #nav-top-undo,
  #nav-top-dragndrop,
  #nav-top-integrations,
  #nav-top-stacked-prs,
  #nav-top-videos,
  #nav-top-book,
  #nav-top-cheatsheets,
  #nav-top-webinar,
  #nav-top-firstaid,
  #nav-top-advanced {
    background-image: none;
    padding-left: 48px;
  }

  .nav-top-secondary .nav-top-secondary-seperator {
    background: rgb(255 255 255 / 30%);
    margin: 8px 48px;
    width: 64px;
  }

  .nav-top-primary > li.nav-top-primary-active > a {
    margin: 0 12px;
    padding: 12px 20px;
  }

  .nav-top-common .nav-top-download {
    margin: 0 32px 16px;
    order: 3 !important;
  }

  .nav-top-download a {
    display: inline-block;
  }
}
