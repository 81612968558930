.hero {
  background: var(--background-primary) url(asset-url('assets/img/illustrations/hero-pattern.svg')) center 260px no-repeat;
  overflow: hidden;
  padding: 0;
}

.hero-wrapper {
  padding: 80px 0 0;
}

.hero-title {
  background: var(--hero-title-color);
  background-clip: text;
  font-size: 52px;
  line-height: 64px;
  margin: 0 auto 16px;
  max-width: 720px;
  padding: 6px 0;
  text-align: center;
  text-shadow: 0 4px 8px rgba(0, 33, 85, 0.2);
  -webkit-text-fill-color: transparent;
}

.current-os-mac .hero-title-mac {
  background: url(asset-url('assets/img/home/mac-underline.svg')) bottom center no-repeat;
  background-size: 100% 20px;
  display: inline-block;
  padding: 8px 14px;
  margin: -8px -14px;
}

.current-os-windows .hero-title-windows {
  background: url(asset-url('assets/img/home/windows-underline.svg')) bottom center no-repeat;
  background-size: 100% 20px;
  display: inline-block;
  padding: 8px 10px;
  margin: -8px -10px;
}

.hero-tagline {
  color: var(--hero-tagline-color);
  font-size: 20px;
  line-height: 28px;
  text-align: center;
  margin: 0 auto 24px;
  max-width: 540px;
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.hero-title span,
.hero-tagline span {
  white-space: nowrap;
}

.hero-links {
  margin: 0 0 44px;
  text-align: center;
}

.hero-download {
  margin-bottom: 8px;
}

.hero-download .button {
  background:
    url(asset-url('assets/img/icons/download.svg')) 21px 11px no-repeat,
    var(--hero-download-button-background);
  border: 0;
  border-radius: 6px;
  box-shadow:
    0 0 0 0.5px rgba(59, 35, 0, 0.2),
    0 2px 4px rgba(59, 35, 0, 0.2),
    inset 0 0.5px 0 rgba(255, 244, 153, 0.3);
  font-size: rem(fontsize(m));
  line-height: 24px;
  padding: 9px 25px 9px 57px;
}

.hero-download .button:hover {
  background:
    url(asset-url('assets/img/icons/download.svg')) 21px 11px no-repeat,
    var(--hero-download-button-background-hover);
}

.hero-download .button:active {
  background:
    url(asset-url('assets/img/icons/download.svg')) 21px 11px no-repeat,
    var(--hero-download-button-background-active);
}

.hero-switch-platform {
  font-size: 12px;
  line-height: 20px;
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.hero-switch-platform .os-switch {
  border-radius: 6px;
  color: var(--hero-switch-platform-color);
  padding: 6px 12px;
  text-decoration: var(--hero-switch-platform-text-decoration);
}

.hero-switch-platform .os-switch:hover {
  background: var(--hero-switch-platform-background-hover);
}

.hero-screenshot {
  margin: 0 auto 0;
  width: 1000px;
}

.hero-screenshot img {
  border-radius: 10px;
  display: block;
  width: 100%;
  height: auto;
  box-shadow: 0 36px 100px rgba(0, 0, 0, 0.4), 0 0 3px rgba(0, 0, 0, 0.55);
}

.hero-screenshot .visible-on--windows img {
  border-radius: 5px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.15), 0 0 0 1px rgba(0, 0, 0, 0.08);
}

.hero-release {
  padding: 80px 0;
  text-align: center;
}

.hero-release-notes {
  font-size: rem(fontsize(base));
  line-height: 20px;
  margin: 0 0 16px;
}

.hero-release-button a {
  background: palette(blue, button);
  border-radius: 6px;
  box-shadow: 0 2px 4px rgba(0, 33, 85, 0.2);
  color: #002155;
  display: inline-block;
  font-size: rem(fontsize(s));
  font-weight: bold;
  line-height: 22px;
  padding: 7px 25px;
  text-decoration: none;
}

.hero-release-button a:hover {
  background: palette(blue, hover);
}

.hero-release-button a:active {
  background: palette(blue, active);
}

@media (max-width: 1100px) {
  .hero-wrapper {
    padding: 60px 12px;
  }

  .hero-tagline {
    margin: 0 auto 40px;
  }

  .hero-links {
    margin: 0 0 40px;
  }

  .hero-release {
    padding: 60px 0 0;
  }

  .hero-screenshot {
    max-width: 1000px;
    width: auto;

    --hero-screenshot-border-radius: 10px;
    --hero-screenshot-shadow: 33px;
  }

  .hero-screenshot img {
    border-radius: var(--hero-screenshot-border-radius);
    box-shadow: 0 var(--hero-screenshot-shadow) calc(var(--hero-screenshot-shadow) * 3) rgba(0, 0, 0, 0.4), 0 0 3px rgba(0, 0, 0, 0.55);
  }

  .hero-screenshot .visible-on--windows {
    --hero-screenshot-border-radius: 5px;
    --hero-screenshot-shadow: 10px;
  }

  .hero-screenshot .visible-on--windows img {
    border-radius: var(--hero-screenshot-border-radius);
    box-shadow: 0 var(--hero-screenshot-shadow) calc(var(--hero-screenshot-shadow) * 2) rgba(0, 0, 0, 0.15), 0 0 0 1px rgba(0, 0, 0, 0.08);
  }
}

@media (max-width: 900px) {
  .hero-screenshot {
    --hero-screenshot-border-radius: 9px;
    --hero-screenshot-shadow: 30px;
  }
}

@media (max-width: 800px) {
  .hero-screenshot {
    --hero-screenshot-border-radius: 8px;
    --hero-screenshot-shadow: 27px;
  }

  .hero-screenshot .visible-on--windows {
    --hero-screenshot-border-radius: 4px;
    --hero-screenshot-shadow: 8px;
  }
}

@media (max-width: 700px) {
  .hero-screenshot {
    --hero-screenshot-border-radius: 7px;
    --hero-screenshot-shadow: 24px;
  }
}

@media (max-width: 600px) {
  .hero-screenshot {
    --hero-screenshot-border-radius: 6px;
    --hero-screenshot-shadow: 21px;
  }

  .hero-screenshot .visible-on--windows {
    --hero-screenshot-border-radius: 3px;
    --hero-screenshot-shadow: 6px;
  }
}

@media (max-width: 500px) {
  .hero-screenshot {
    --hero-screenshot-border-radius: 5px;
    --hero-screenshot-shadow: 18px;
  }
}

@media (max-width: 400px) {
  .hero-screenshot {
    --hero-screenshot-border-radius: 4px;
    --hero-screenshot-shadow: 15px;
  }

  .hero-screenshot .visible-on--windows {
    --hero-screenshot-border-radius: 2px;
    --hero-screenshot-shadow: 3px;
  }
}

@media (max-width: 760px) {
  .hero-title {
    font-size: 32px;
    line-height: 40px;
  }

  .hero-title span,
  .hero-tagline span {
    white-space: normal;
  }

  .hero-wrapper {
    padding: 40px 12px;
  }

  .hero-tagline {
    margin: 0 auto 20px;
  }

  .hero-links {
    margin: 0 0 20px;
  }

  .hero-release {
    padding: 20px 0 0;
  }
}
